import NextHead from 'next/head';
import { useCmsData } from 'utils/cms';

const Head = () => {
  const { yoastHeadJson, generalSettings } = useCmsData();

  return (
    <NextHead>
      {yoastHeadJson.og_site_name && <meta content={yoastHeadJson.og_site_name} property="og:site_name" />}
      {yoastHeadJson.title && <meta content={yoastHeadJson.title} property="og:title" />}
      {yoastHeadJson.og_type && <meta content={yoastHeadJson.og_type} property="og:type" />}
      {(yoastHeadJson.og_description || generalSettings.defaultDescription) && (
        <meta content={yoastHeadJson.og_description || generalSettings.defaultDescription} property="og:description" />
      )}
      {yoastHeadJson.og_url && <meta content={yoastHeadJson.og_url} property="og:url" />}
      {yoastHeadJson.article_modified_time && (
        <meta content={yoastHeadJson.article_modified_time} property="og:updated_time" />
      )}
      {yoastHeadJson.og_image ? (
        yoastHeadJson.og_image.map(({ url }) => <meta key={url} content={url} property="og:image" />)
      ) : (
        <meta key={generalSettings.defaultOgImage} content={generalSettings.defaultOgImage} property="og:image" />
      )}

      <title>{yoastHeadJson.title}</title>
      <link href="/site.webmanifest" rel="manifest" />
      <link href="/apple-touch-icon.png" rel="apple-touch-icon" />
    </NextHead>
  );
};
export default Head;
