import dynamic from 'next/dynamic';
import { TemplateType } from 'types/cms';

/*
In VSCODE : ctlr + maj + p, then type "sort line ascending"
thx ⬇️⬇️⬇️
*/

const TEMPLATE_MAPPER: Record<string, TemplateType> = {
  Admin: dynamic(() => import('./Form/Admin')),
  Alterators: dynamic(() => import('./Alterators/list')),
  AlteratorsDetails: dynamic(() => import('./Alterators/details')),
  CardExchange: dynamic(() => import('components/template/CardExchange/error/')),
  CardExchangeDetails: dynamic(() => import('components/template/CardExchange/success/')),
  Cards: dynamic(() => import('components/template/Cards/list')),
  CardsDetails: dynamic(() => import('./Cards/details')),
  CardsLists: dynamic(() => import('components/template/CartaMundi/CardsLists')),
  CardsListsView: dynamic(() => import('components/template/CartaMundi/CardsListView')),
  CardsSales: dynamic(() => import('components/template/Marketplace/CardsSales')),
  ContactUs: dynamic(() => import('./Form/ContactUs')),
  Content: dynamic(() => import('./Content')),
  Decks: dynamic(() => import('./Decks/list')),
  DecksCreate: dynamic(() => import('./Decks/create')),
  DecksDetails: dynamic(() => import('./Decks/details')),
  EventLocator: dynamic(() => import('components/template/Locator/Events')),
  Events: dynamic(() => import('./Events/list')),
  EventsDetails: dynamic(() => import('./Events/details')),
  Factions: dynamic(() => import('./Factions/home')),
  FactionsDetails: dynamic(() => import('./Factions/details')),
  Form: dynamic(() => import('./Form')),
  Games: dynamic(() => import('components/template/Games/list/me')),
  GamesDetails: dynamic(() => import('components/template/Games/details/GamesDetails')),
  GamesEvent: dynamic(() => import('components/template/Games/list/events/GamesEvent')),
  MarketPaymentCheckout: dynamic(() => import('components/template/Marketplace/MarketPaymentCheckout')),
  MarketPaymentCheckoutDetails: dynamic(() => import('components/template/Marketplace/MarketPaymentCheckout/Success')),
  Marketplace: dynamic(() => import('components/template/Marketplace/Marketplace')),
  MyOffers: dynamic(() => import('components/template/Marketplace/MyOffers/list')),
  News: dynamic(() => import('./News/list')),
  NewsDetails: dynamic(() => import('./News/details')),
  Notifications: dynamic(() => import('./profile/Notifications')),
  MyExchanges: dynamic(() => import('./profile/MyExchanges')),
  MyExchangesDetails: dynamic(() => import('./profile/MyExchanges/details')),
  DecksMultiscan: dynamic(() => import('./Multiscan/Decks')),
  MultiscanPartner: dynamic(() => import('./Multiscan/Partner')),
  MultiscanCollection: dynamic(() => import('./Multiscan/Collection')),
  Printing: dynamic(() => import('components/template/Printing')),
  PrintingCheckout: dynamic(() => import('components/template/Printing/checkout')),
  PrintingDetails: dynamic(() => import('components/template/Printing/details')),
  PrintingPaymentSummary: dynamic(() => import('components/template/Printing/summary')),
  PrintingTracking: dynamic(() => import('./Printing/tracking')),
  Profile: dynamic(() => import('./profile/Player')),
  ProfileDetails: dynamic(() => import('./profile/Public')),
  Friends: dynamic(() => import('./profile/Friends')),
  ScanDetails: dynamic(() => import('components/template/Scan')),
  ShopProfile: dynamic(() => import('./profile/Shop')),
  StoreLocator: dynamic(() => import('components/template/Locator/Shop')),
  SurveysDetails: dynamic(() => import('./Surveys/SurveyDetails')),
  TabbedContent: dynamic(() => import('./TabbedContent')),
  Transactions: dynamic(() => import('components/template/Marketplace/Transactions/list')),
  TransactionsDetails: dynamic(() => import('components/template/Marketplace/Transactions/details')),
};

export default TEMPLATE_MAPPER;
