/* eslint-disable no-console */
import * as Sentry from '@sentry/nextjs';
import { QueryClient, dehydrate } from '@tanstack/react-query';
import { GetServerSideProps } from 'next';
import { getServerSession } from 'next-auth/next';
import { useSession } from 'next-auth/react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import DefaultErrorPage from 'next/error';
import { useEffect } from 'react';
import { setLocale } from 'yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createApiClient } from '@/api/client';
import cookie from 'cookie';
import useDateFnsWithLocale from 'hooks/i18n/useDateFnsWithLocale';
import getErrorI18nConfig from 'i18n/errorConfig';
import { getServerSignInUrl, serializeSession, shouldRedirectRegister } from 'utils/auth';
import LAYOUT_MAPPER from '../components/layouts/mapper';
import Head from '../components/organisms/Head/Head';
import TEMPLATE_MAPPER from '../components/template/mapper';

import useKeycloak from 'hooks/useKeycloak';
import { env } from 'next-runtime-env';
import NProgress from 'nprogress';
import { handleCookies } from 'utils/ssr/cookies';
import { isInAppWebview, onStopProgress } from 'utils/webview';
import { DtoPage } from '../types/cms';
import { fetchSlug, parseSlug, useCmsData } from '../utils/cms';
import { authOptions } from './api/auth/[...nextauth]';

const COOKIE_MOBILE_APP = 'mobile-app';
const COOKIE_USER_ACTIVE = 'user-active';

const WpEntry = () => {
  const data = useCmsData();
  const Component = data && TEMPLATE_MAPPER[data.template];
  const Layout = LAYOUT_MAPPER[data.acf.layout];
  const session = useSession();
  const { keycloakSignin } = useKeycloak();

  const { format } = useDateFnsWithLocale();
  setLocale(getErrorI18nConfig(format));

  useEffect(() => {
    if (session.data?.error === 'RefreshAccessTokenError') {
      keycloakSignin();
    }
  }, [session]);

  useEffect(() => {
    if (isInAppWebview()) {
      onStopProgress(() => {
        NProgress.done();
      });
    }
  }, []);

  if (!Component || !Layout) {
    return <DefaultErrorPage statusCode={404} />;
  }

  return (
    <Layout
      acf={data.acf}
      background={data.acf.background}
      template={data.template}
      useFooter={data.acf.footer !== 'hidden'}
      useHeader={data.acf.header !== 'hidden'}
    >
      <Head />
      <Component />
    </Layout>
  );
};

export default WpEntry;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { req, res, locale, query } = ctx;
  res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');

  const cookies = cookie.parse(req.headers.cookie || '');
  const cookiesToDelete: string[] = [];
  const cookiesToSet: Record<string, string> = {};

  // Set cookie first time from mobile app header
  if (req.headers['x-mobile-app']) {
    cookiesToSet[COOKIE_MOBILE_APP] = 'true';
  }

  const isMobileApp = !!req.headers['x-mobile-app'] || !!cookies[COOKIE_MOBILE_APP];

  const { slug, ...params } = query;
  const url = parseSlug(slug);

  const redirectTo404 = {
    props: {
      ...(locale ? await serverSideTranslations(locale) : {}),
    },
    notFound: true,
  };

  let session = await getServerSession(req, res, authOptions);

  if (session?.error === 'RefreshAccessTokenError') {
    Object.entries(req.cookies).forEach(([name]) => {
      if (name.includes('next-auth')) {
        cookiesToDelete.push(name);
      }
    });

    session = null;
  }

  const apiClient = createApiClient(url !== '/login' ? session?.accessToken ?? session?.jwt : undefined);
  const queryKey = [url, { locale, ...params }, (session?.accessToken ?? session?.jwt) || 'no-auth'];

  const queryClient = new QueryClient();
  await queryClient.prefetchQuery({
    queryKey,
    queryFn: (): Promise<DtoPage> => fetchSlug(apiClient, url, locale, params).catch((e) => e),
  });

  // Register wall
  if (session?.accessToken && !cookies[COOKIE_USER_ACTIVE]) {
    try {
      const user = await apiClient.me.apiMeGet().then((userResponse) => userResponse.data);
      const shouldRedirectRegisterUrl = await shouldRedirectRegister(user, req.url!);

      if (shouldRedirectRegisterUrl) {
        return {
          redirect: {
            destination: user.isActive ? '/' : `/${locale}${shouldRedirectRegisterUrl}`,
            permanent: false,
          },
        };
      }

      cookiesToSet[COOKIE_USER_ACTIVE] = 'true';
    } catch (e) {
      console.error('Error while redirecting in register flow', e);
    }
  }

  cookiesToDelete.push(COOKIE_USER_ACTIVE);
  const data: DtoPage | undefined = await queryClient.getQueryData(queryKey);

  if (process.env.NODE_ENV === 'development') {
    console.log('----');
    console.log('fetching SSG : ');
    console.log(queryKey);
    console.log('Data : ');
    console.log(data);
    // @ts-expect-error
    console.log(data?.error);
    console.log('----');
  }

  if (data && 'error' in data) {
    Sentry.captureException(data.error);
  }

  handleCookies(res, cookiesToSet, cookiesToDelete);

  if (Number(data?.status) === 401) {
    const queryParams = {
      ...query,
    };
    if (Array.isArray(query?.slug)) {
      queryParams.redirect = query?.slug.join('/');
    }

    const { redirect } = await getServerSignInUrl(
      req,
      res,
      locale?.split('-')[0],
      isMobileApp,
      new URL(`/${locale}/${(queryParams.redirect as string) ?? ''}`, env('NEXTAUTH_URL')),
    );

    return {
      redirect: {
        destination: redirect,
        permanent: false,
      },
    };
  }

  if (data && data.acf?.redirectToFirstChild) {
    if (!data?.children) {
      return redirectTo404;
    }

    return {
      props: {
        ...(locale ? await serverSideTranslations(locale, undefined, null, ['en-us', 'fr-fr']) : {}),
      },
      redirect: { destination: `/${locale}/${data.children[0].url}`, permanent: false },
    };
  }

  let template = data && TEMPLATE_MAPPER[data.template];

  // @ts-expect-error preload is a hidden API function from next/dynamic
  if (template && template.render?.preload) {
    // @ts-expect-error preload is a hidden API function from next/dynamic
    template = (await template.render.preload()).default;
  }

  if (!template || !data) {
    return redirectTo404;
  }

  const serverSideFunction = template.getServerSideFunction
    ? await template.getServerSideFunction(data.acf, apiClient)
    : undefined;
  const customSSRprops =
    serverSideFunction && (await serverSideFunction({ queryClient, query, acf: data.acf, apiClient }));

  return {
    props: {
      ...(locale ? await serverSideTranslations(locale, undefined, null, ['en-us', 'fr-fr']) : {}),
      locale,
      dehydratedState: dehydrate(queryClient),
      session: session ? serializeSession(session) : null,
      generalSettings: data.generalSettings || null,
      ...(customSSRprops && 'props' in customSSRprops ? customSSRprops.props : {}),
      isMobileApp,
    },
  };
};
