/* eslint-disable camelcase */
import { useConfig } from 'context/Config';
import { signIn } from 'next-auth/react';
import { useTranslation } from 'react-i18next';

type UserType = 'shop' | 'customer';

const useKeycloak = (userType: UserType = 'customer') => {
  const { isMobileApp } = useConfig();
  const {
    i18n: { language },
  } = useTranslation();

  const getRedirectUri = () => {
    if (isMobileApp) {
      // eslint-disable-next-line camelcase
      return userType === 'shop' ? 'altered://auth/redirect-shop' : 'altered://auth/redirect';
    }

    return undefined;
  };

  const getKeycloakProviderId = (type = userType) => {
    if (isMobileApp) {
      return type === 'shop' ? 'keycloak-shop-mobile' : 'keycloak-mobile';
    }

    return type === 'shop' ? 'keycloak-shop' : 'keycloak';
  };

  const getExtraParams = (extraParams?: Record<string, string>) => {
    const params: Record<string, string> = {};

    const redirectUri = getRedirectUri();

    if (redirectUri) {
      params.redirect_uri = redirectUri;
    }

    if (language) {
      // eslint-disable-next-line prefer-destructuring
      params.ui_locales = language.split('-')[0];
    }

    /**
     * We need to set the appropriate redirect url based on which realm we switch on.
     * If we switch on the players realm, we need to redirect to the correct callback, and
     * still set the appropriate redirect_uri for the shop realm in case there is a switch.
     *
     * Player realm needs the info about the shop realm redirect uri
     * Shop realm needs the info about the player realm redirect uri
     *
     * see auth/themes/altered/login/login.ftl
     */
    if (userType === 'shop') {
      if (isMobileApp) {
        params.players_redirect_uri = 'altered://auth/redirect-shop';
      } else {
        params.players_redirect_uri = new URL(
          `/api/auth/callback/${getKeycloakProviderId('customer')}`,
          window.location.origin,
        ).toString();
      }
    } else if (userType === 'customer') {
      if (isMobileApp) {
        params.shop_redirect_uri = 'altered://auth/redirect';
      } else {
        params.shop_redirect_uri = new URL(
          `/api/auth/callback/${getKeycloakProviderId('shop')}`,
          window.location.origin,
        ).toString();
      }
    }

    return { ...params, ...extraParams };
  };

  const keycloakSignin = (extraParams?: Record<string, string>) =>
    signIn(getKeycloakProviderId(), undefined, getExtraParams(extraParams));

  return { keycloakSignin };
};

export default useKeycloak;
